export function logoutStates($stateProvider) {
  'ngInject';

  $stateProvider
    // Navigating to this state should be the preferred way of logging out. It clears the screen immediately as
    // opposed to requiring the API to return before hiding sensitive data from the screen.
    .state('logout', {
      onEnter: (acpAuthModel) => {
        'ngInject';
        acpAuthModel.logout();
      },
      parent: 'defaultlayout',
      resolve: {
        module: ($ocLazyLoad) => {
          'ngInject';
          return import(/* webpackChunkName: "acp.section.logout" */ 'apps/logout').then(
            $ocLazyLoad.loadModule
          );
        }
      },
      template: '<acp-logout />',
      url: '/logout'
    });
}
